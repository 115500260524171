import React from 'react'

import { graphql } from 'gatsby'

import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

import SEO from '../components/seo'
import Layout from '../components/layout'

import './ContentfulPage.scss'

const PageTemplate = ({ data: { page } }) => (
  <Layout>
    <SEO
      bodyAttributes={{ class: 'page' }}
      title={page.externalTitle}
      description={page.seoDescription}
    />

    <div>
      <div className="pageBodyContent">
        <h1>{page.externalTitle}</h1>

        {documentToReactComponents(page.content.json)}
      </div>
    </div>
  </Layout>
)

export const query = graphql`
  query($slug: String!) {
    page: contentfulPage(slug: { eq: $slug }) {
      slug
      externalTitle
      seoDescription
      content {
        json
      }
    }
  }
`

export default PageTemplate
